import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Landing from "./views/landing";
import Bestilling from "./views/Bestilling";
import AdminView from "./views/admin";
import { Helmet } from "react-helmet";

function App() {


  return (
    <div className="App">
      <Helmet>
        <meta name="keywords" content="coaching, online coaching, samtaler, stress, angst, nætverksamtaler, nada, parsamtaler, coaching kolding, coach kolding, coach, NADA-akupunktør, Metakognitiv terapi, Gestaltterapi, Psykoterapeut, Psykoterapeut MPF, MPF, Psykoterapi, NLP, depression, online psykoterapi" />
        <meta name="description" content="Psykoterapi for alle og en hver der har brug for lidt hjælp på vejen gennem livet." />
        <link
          rel="icon"
          type="image/png"
          href="https://icons.duckduckgo.com/ip3/www.google.com.ico"
          sizes="16x16"
        />
      </Helmet>

      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/bestilling" element={<Bestilling />} />
          <Route path="/admin" element={<AdminView />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
