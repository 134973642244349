import React, { useState, useeffect, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { getLocalUser, setLocalUser } from "./localstorage";
import {
    Title,
    TextInput,
    PasswordInput,
    NumberInput,
    Textarea,
    Tooltip,
    Modal,
    Button,
    Loader,
} from "@mantine/core";
import {
    Forms,
    At,
    Hash,
    Lock,
    Message,
    QuestionMark,
    Disabled,
    Check,
    AlertCircle,
} from "tabler-icons-react";
import "../../style/bestilling/info.scss";
import Appointments from "./appointments";
import useWindowSize from "../helpers/useWindowSize";

import { SHA256 } from "crypto-js";
import { toast } from "react-toastify";
import getIp from "../helpers/getIp";

const Info = (props) => {
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [infoFilled, setInfoFilled] = useState(true);
    const [completeModal, setCompleteModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [oldError, setOldError] = useState(false);
    const [viewAppointments, setViewAppointments] = useState(false);
    const [appointmentSize, setAppointmentSize] = useState("xl");
    const { width } = useWindowSize();

    useEffect(() => {
        console.log(props.formTime);
        if (!props.loggedIn) {
            if (
                props.formDate == null ||
                props.formTime == null ||
                props.nameValue === "" ||
                props.lastNameValue === "" ||
                props.emailValue === "" ||
                props.phoneValue === "" ||
                props.passValue === ""
            ) {
                setInfoFilled(false);
                return;
            } else {
                console.log(infoFilled);
                setInfoFilled(true);
                return;
            }
        } else {
            if (props.formTime == null) {
                console.log("nullllllllllll");
                setInfoFilled(false);
                return;
            } else {
                setInfoFilled(true);
            }
        }

        if (props.formDate <= new window.Date()) {
            setInfoFilled(false);
            // toast("Du kan ikke bestille en tid i fortiden", {
            //     type: "error",
            //     pauseOnHover: false,
            //     position: "bottom-center",
            // });
        } else {
            setInfoFilled(true);
        }
    }, [
        props.formDate,
        props.formTime,
        props.nameValue,
        props.lastNameValue,
        props.emailValue,
        props.phoneValue,
        props.passValue,
    ]);

    const clickSubmit = async () => {
        if (!props.loggedIn) {
            try {
                await axios.post(
                    `https://ckapi.justdiv.studio/login?ip=${await getIp()}`,
                    {
                        name: props.nameValue,
                        surname: props.lastNameValue,
                        email: props.emailValue,
                        phone: props.phoneValue.toString(),
                        password: SHA256(props.passValue).toString(),
                    }
                );
                setCompleteModal(true);
                console.log("Successfully created user.");
                try {
                    await axios
                        .post(
                            `https://ckapi.justdiv.studio/appointments?ip=${await getIp()}`,
                            {
                                owner: props.emailValue,
                                date: props.formDate.getDate(),
                                month: props.formDate.getMonth(),
                                year: props.formDate.getFullYear(),
                                time: props.formTime,
                                comment:
                                    props.commentValue === ""
                                        ? "Ingen kommentar"
                                        : props.commentValue,
                                treatment: props.treatment,
                            }
                        )
                        .then(() => {
                            setTimeout(() => setSuccess(true), 1000);
                        });
                } catch (error) {
                    console.log(error);
                    setTimeout(() => setError(true), 1000);
                }
            } catch (error) {
                console.log("Something went wrong. " + error);
                setCompleteModal(true);
                setTimeout(() => setError(true), 1000);
            }
        } else {
            setCompleteModal(true);
            try {
                console.log(getLocalUser().email);
                await axios
                    .post(
                        `https://ckapi.justdiv.studio/appointments?ip=${await getIp()}`,
                        {
                            owner: getLocalUser().email,
                            date: props.formDate.getDate(),
                            month: props.formDate.getMonth(),
                            year: props.formDate.getFullYear(),
                            time: props.formTime,
                            comment:
                                props.commentValue === ""
                                    ? "Ingen kommentar"
                                    : props.commentValue,
                            treatment: props.treatment,
                        }
                    )
                    .then(() => {
                        setTimeout(() => setSuccess(true), 1000);
                    });
            } catch (error) {
                console.log(error);
                setTimeout(() => setError(true), 1000);
            }
        }
    };

    const clickLogin = async (e) => {
        if (e) {
            e.preventDefault();
        }
        try {
            const res = await axios.get(
                `https://ckapi.justdiv.studio/login?email=${emailValue}&password=${SHA256(
                    passwordValue
                ).toString()}&ip=${await getIp()}`
            );
            console.log("Logged in!");
            toast("Du er nu logget ind!", {
                type: "success",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: false,
            });
            const user = res.data.user;
            setLocalUser(user);
            props.setUser(getLocalUser());
            props.setLoginModal(false);
        } catch {
            console.log("Email or password incorrect");
            toast("Email eller adgangskode forkert", {
                type: "error",
                position: "bottom-center",
                autoClose: 5000,
                closeOnClick: true,
                pauseOnHover: false,
            });
        }
    };

    return (
        <section
            className="info section px-5 pb-5
    "
        >
            <Modal
                centered
                opened={props.loginModal}
                onClose={() => props.setLoginModal(false)}
                title="Log ind"
                overlayBlur={3}
                overlayOpacity={0.2}
                className="login-modal"
            >
                <form onSubmit={(e) => clickLogin(e)}>
                    <div className="login-wrapper flex flex-col gap-3">
                        <TextInput
                            className=""
                            placeholder="john@doe.com"
                            onChange={(event) =>
                                setEmailValue(event.target.value.toLowerCase())
                            }
                            label="Email adresse"
                        />
                        <PasswordInput
                            placeholder="*********"
                            onChange={(event) =>
                                setPasswordValue(event.target.value)
                            }
                            label="Adgangskode"
                        />
                        <Button type="submit" className="bestilling-button">
                            Log ind
                        </Button>
                    </div>
                </form>
            </Modal>

            <Modal
                centered
                opened={completeModal}
                onClose={() => document.location.reload()}
                title="Bestilling"
                overlayBlur={3}
                overlayOpacity={0.2}
                className="login-modal"
            >
                <div className="login-wrapper">
                    {success == true ? (
                        <>
                            <Title
                                order={2}
                                className="bestillingSuccess flex gap-5 items-center"
                            >
                                <Check size={30} />
                                Din bestilling er sendt
                                {!props.loggedIn
                                    ? ", og ny bruger er lavet!"
                                    : "!"}
                            </Title>
                            {!props.loggedIn ? (
                                <Button
                                    onClick={() => {
                                        setCompleteModal(false);
                                        props.setLoginModal(true);
                                    }}
                                >
                                    Log ind
                                </Button>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : error == true ? (
                        <>
                            <Title order={2} className="bestillingSuccess">
                                <AlertCircle size={30} />
                                Noget gik galt!
                            </Title>
                            <Title order={3}>Prøv igen senere...</Title>
                            <Button
                                onClick={() => document.location.reload()}
                                style={{ color: "white!important" }}
                            >
                                Genindlæs
                            </Button>
                        </>
                    ) : (
                        <>
                            <Title
                                order={2}
                                className="bestillingSuccess flex gap-5 items-center"
                            >
                                <Loader /> Sender din bestilling...
                            </Title>
                        </>
                    )}
                </div>
            </Modal>

            <Modal
                centered
                opened={viewAppointments}
                onClose={() => {
                    setViewAppointments(false);
                    setTimeout(() => setAppointmentSize("xl"), 500);
                }}
                title="Dine tider"
                overlayBlur={3}
                overlayOpacity={0.2}
                size={appointmentSize}
                fullScreen={width < 550 ? true : false}
                padding={width < 550 ? 5 : 20}
                overflow="inside"
            >
                <Appointments
                    viewAppointments={viewAppointments}
                    appointmentSize={appointmentSize}
                    setAppointmentSize={(size) => setAppointmentSize(size)}
                />
            </Modal>

            <Title order={3} className="text-center">
                Information
            </Title>
            <form className="infoForm pt-5 flex flex-col gap-5">
                {props.loggedIn ? (
                    <div className="loggedIn">
                        <p className="username">Hej {props.user.name}!</p>
                        <p>
                            Er det ikke dig? så{" "}
                            <span
                                className="formLink"
                                onClick={() => props.signOut()}
                            >
                                log ud
                            </span>
                        </p>
                        <Button onClick={() => setViewAppointments(true)}>
                            Se dine tider
                        </Button>
                    </div>
                ) : (
                    <>
                        <TextInput
                            onChange={(event) =>
                                props.setNameValue(event.currentTarget.value)
                            }
                            placeholder="John"
                            label="Fornavn"
                            description="Hvad er dit navn?"
                            icon={<Forms size={13} />}
                            required
                            className=""
                            size="md"
                        />
                        <TextInput
                            onChange={(event) =>
                                props.setLastNameValue(
                                    event.currentTarget.value
                                )
                            }
                            placeholder="Smith"
                            label="Efternavn"
                            description="Hvad er dit efternavn?"
                            icon={<Forms size={13} />}
                            required
                            className=""
                            size="md"
                        />
                        <TextInput
                            onChange={(event) =>
                                props.setEmailValue(
                                    event.currentTarget.value.toLowerCase()
                                )
                            }
                            placeholder="johnsmith@domæne.dk"
                            label="Email"
                            description="Email adresse bruger vi til, at du senere kan logge ind"
                            icon={<At size={13} />}
                            required
                            className=""
                            size="md"
                            rightSection={
                                <Tooltip withArrow label="Vi sender ikke spam">
                                    <QuestionMark size={15} />
                                </Tooltip>
                            }
                        />
                        <TextInput
                            onChange={(val) =>
                                props.setPhoneValue(val.target.value)
                            }
                            placeholder="12 34 56 78"
                            label="Telefonnummer"
                            description="Hvordan kommer jeg i kontakt med dig?"
                            hideControls
                            icon={<Hash size={13} />}
                            required
                            className=""
                            size="md"
                        />
                        <Tooltip
                            withArrow
                            label="Du mister dine tider, hvis du glemmer adgangskoden."
                            position="bottom"
                        >
                            <PasswordInput
                                onChange={(event) =>
                                    props.setPassValue(
                                        event.currentTarget.value
                                    )
                                }
                                placeholder="********"
                                label="Adgangskode"
                                description="Husk din adgangskode, så du senere kan se dine tider"
                                icon={<Lock size={13} />}
                                required
                                className=""
                                size="md"
                                rightSection={
                                    <Tooltip
                                        withArrow
                                        label="Vi laver en konto til dig, så du senere kan se dine tider"
                                    >
                                        <QuestionMark size={15} />
                                    </Tooltip>
                                }
                            />
                        </Tooltip>
                    </>
                )}
                <Textarea
                    onChange={(event) =>
                        props.setCommentValue(event.currentTarget.value)
                    }
                    placeholder="Din kommentar"
                    label="Kommentar"
                    description="Er der noget ekstra, jeg skal vide?"
                    icon={<Message size={13} />}
                    // required
                    className=""
                    size="md"
                />
                <Button
                    className="bestilling-button"
                    onClick={() => clickSubmit()}
                    disabled={infoFilled ? false : true}
                    error={true}
                >
                    {" "}
                    Send bestilling
                </Button>
                {!props.loggedIn ? (
                    <p className="preUser">
                        Har du allerede en konto?{" "}
                        <span
                            className="formLink"
                            onClick={() => props.setLoginModal(true)}
                        >
                            Så log ind her
                        </span>
                    </p>
                ) : (
                    <></>
                )}
            </form>
        </section>
    );
};

export default Info;
