import React, { useEffect, useState, useRef } from "react";
import mapboxgl from "mapbox-gl";
import useWindowSize from "../helpers/useWindowSize";

mapboxgl.accessToken =
    "pk.eyJ1IjoiZGFzaHhkIiwiYSI6ImNrbzJrOTZ3dzBod2YycHMya2NrcG5mZG0ifQ.0Gvv2g7BssXEzgxD0JjRYw";

const Map = () => {
    // const mapContainer = useRef(null);
    const map = useRef(null);
    const [lat, setLat] = useState(55.52614146243467);
    const [lng, setLng] = useState(9.469273915418325);
    const [zoom, setZoom] = useState(15);
    const [w, setW] = useState(0);
    const [h, setH] = useState(0);
    const { width, height } = useWindowSize();

    //https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(9.469320991587965,55.526104394706046)/9.4695,55.526,15.55,0/723x753?access_token=pk.eyJ1IjoiZGFzaHhkIiwiYSI6ImNrbzJrOTZ3dzBod2YycHMya2NrcG5mZG0ifQ.0Gvv2g7BssXEzgxD0JjRYw

    useEffect(() => {
        // if (map.current) return; // initialize map only once
        // map.current = new mapboxgl.Map({
        //     container: mapContainer.current,
        //     style: "mapbox://styles/dashxd/cl6giuflt002014ljhx4xqqip",
        //     center: [lng, lat],
        //     zoom: zoom,
        // });
        setW(Math.floor(map.current.getBoundingClientRect().width));
        setH(Math.floor(map.current.getBoundingClientRect().height));
    }, [width, height]);

    return (
        <div className="h-full" ref={map}>
            {/* <div ref={mapContainer} className="map-container h-full" /> */}
            <img
                src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+555555(9.469320991587965,55.526104394706046)/9.4695,55.526,15.55,0/${w}x${h}?access_token=pk.eyJ1IjoiZGFzaHhkIiwiYSI6ImNrbzJrOTZ3dzBod2YycHMya2NrcG5mZG0ifQ.0Gvv2g7BssXEzgxD0JjRYw`}
                alt=""
                style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                }}
                onClick={() => {
                    window.open(
                        "https://www.google.com/maps/place/Grydh%C3%B8j+16,+6000+Kolding/@55.5258823,9.468812,17.33z/data=!4m5!3m4!1s0x464c9e53578101e9:0xfa64181195e0a680!8m2!3d55.5260952!4d9.469339",
                        "_blank"
                    );
                }}
            />
        </div>
    );
};

export default Map;
