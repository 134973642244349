import React from 'react'

const Loader = () => {
    return (
        // <div className="h-screen w-screen flex mx-auto my-auto">
        //     <div class="mx-auto my-auto border-full w-12 h-12 border-4 border-[color:var(--primary)] rounded-full loader"></div>
        // </div >
        <div id='loader'></div>
    )
}

export default Loader