import React from "react";

const Analytics = (props) => {
    return (
        <iframe
            src="https://analytics.justdiv.studio/share/FCvMRtu9/Coach%20Kolding"
            frameborder="0"
            style={{ width: "100%", height: "100%" }}
        ></iframe>
    );
};

export default Analytics;
