import React, { useState, useeffect } from "react";
import { Title, ScrollArea } from "@mantine/core";
import "../../style/bestilling/treatment.scss";
import data from "../../assets/data.json";

const Treatment = (props) => {
    return (
        <>
            <ScrollArea className="treatment section  px-5 h-full pb-5
            ">
                <Title className="pb-2 md:pb-5 text-center" order={3}>
                    Behandling
                </Title>
                <div className="grid grid-cols-2 gap-5">
                    {data.treatments.map((item, index) => (
                        <div
                            className={`treatment-card  border-2   text-center rounded-lg p-1 md:p-5 cursor-pointer select-none bg-white ease-in-out duration-200 ${
                                props.treatment === item.id
                                    ? "border-[color:var(--primary)] drop-shadow-lg"
                                    : ""
                            }`}
                            onClick={() => props.setTreatment(item.id)}
                            key={item.name}
                        >
                            <div className="treatment-card__header">
                                <img src={item.image} alt="" />
                                <Title
                                    order={3}
                                    className={`${!item.subtitle ? "mb-1" : "mb-0"} text-base md:text-lg`}
                                >
                                    {item.name}
                                </Title>
                                {item.subtitle && (
                                    <p className="mb-1 text-gray-400 text-xs
                                    md:text-base">
                                        {item.subtitle}
                                    </p>
                                )}
                                <p className="hidden md:block" 
                                style={{ fontFamily: "sans-serif" }}>
                                    {item.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </ScrollArea>
        </>
    );
};

export default Treatment;
