import React from "react";
import ContactInfo from "./ContactInfo";
import Map from "./Map";
import Form from "./Form";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Contact = () => {
  return (
    <section
      className="snap-top bg-[color:var(--secondary)]   p-2 min-h-max pb-5"
      id="contact"
    >
      <div className="section-container">
        <h3 className="text-center font-bold text-3xl pb-8 pt-20">Kontakt</h3>

        <AnimationOnScroll animateIn="animate__fadeIn">
          <div
            className="flex h-full  container mx-auto
    lg:px-5 
       "
          >
            <div className="w-full h-full lg:gap-12  lg:w-1/2 lg:flex lg:flex-col my-auto">
              <div
                className="  py-6
          lg:w-full 
          lg:px-12
          "
              >
                <ContactInfo />
              </div>

              <div
                className="w-full
           lg:w-full lg:px-12 lg:py-0
           "
              >
                <Form />
              </div>
            </div>

            <div
              className="bg-red-50  absolute 
        md:w-96 h-36 w-44 md:right-12 right-1
         lg:block lg:h-2/3 lg:w-2/5 lg:pt-10 p-0"
              style={{ paddingTop: 0 }}
            >
              <Map />
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </section>
  );
};
export default Contact;
