import React, { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Hero from "../components/Hero";
import About from "../components/about/About";
import Treatments from "../components/Treatments";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";
import Bestil from "../components/bestil/Bestil";

import { ToastContainer } from "react-toastify";

import Loader from "../components/helpers/Loader";
import Error from "../components/helpers/Error";

function Landing() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1900);
  }, []);

  return (
    <div className="App  snap-y max-w-screen">
      {loading ? (
        <Loader />
      ) : (
        <div className=" ">
          <ToastContainer />
          <Nav />
          <Hero />
          <About />
          <Treatments />
          <Bestil />
          <Contact />
          <Footer />
        </div>
      )}
    </div>
  );
}

export default Landing;
