import React, { useState, useEffect } from "react";
import { Title, Button, Select, Switch } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import axios from "axios";
import { toast } from "react-toastify";
import getIp from "../helpers/getIp";

const SettingsPanel = () => {
    const [dateValues, setDateValue] = useState([]);
    const [datesUnavailable, setDatesUnavailable] = useState([]);
    const [weekendsEnabled, setWeekendsEnabled] = useState(false);

    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);

    const times = () => {
        let times = [];
        for (let i = 0; i <= 24; i++) {
            times.push({ value: i, label: `${i < 10 ? `0${i}` : i}:00` });
        }
        return times;
    };

    const getWeekendEnabled = async () => {
        await axios
            .get("https://ckapi.justdiv.studio/settings?type=WEEKENDS-ENABLED")
            .then((res) => {
                setWeekendsEnabled(res.data.setting.value.enabled);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getTimesUnavailable();
        getTimes();
        getWeekendEnabled();
    }, []);

    const getTimes = async () => {
        await axios
            .get(`https://ckapi.justdiv.studio/settings?type=AVAILABLE-TIMES`)
            .then((res) => {
                const times = res.data.setting.value;
                setFrom(times.from);
                setTo(times.to);
            })
            .catch((err) => {});
    };

    const getTimesUnavailable = async () => {
        setDatesUnavailable([]);
        axios
            .get("https://ckapi.justdiv.studio/settings?type=TIMES-EXCLUDED")
            .then((res) => {
                const times = res.data.setting.value.times;
                let newTimes = [];
                for (let i = 0; i < times.length; i++) {
                    const date = new Date(Number(times[i]));
                    newTimes.push(date);

                    // if (newTimes.includes(times[i])) {
                    //     console.log(true);
                    // } else {
                    //     const date = new Date(Number(times[i]));
                    //     setDatesUnavailable((datesUnavailable) => [
                    //         ...datesUnavailable,
                    //         date,
                    //     ]);
                    //     setDateValue((DateValues) => [...DateValues, date]);
                    // }
                }
                const sortedTimes = [...new Set(newTimes)];
                setDatesUnavailable(sortedTimes);
                setDateValue(sortedTimes);
            })
            .catch((err) => {
                console.log(err);
                toast("Der opstod en fejl!", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    const saveDates = async () => {
        const times = [];
        for (let i = 0; i < dateValues.length; i++) {
            times.push(String(dateValues[i].getTime()));
        }
        console.log(times);
        axios
            .post(
                `https://ckapi.justdiv.studio/settings/update?type=TIMES-EXCLUDED&ip=${await getIp()}`,
                {
                    value: {
                        times: times,
                    },
                }
            )
            .then((res) => {
                console.log("Times saved!");
                toast("Data er gemt", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            })
            .catch((err) => {
                console.log(err);
                toast("Der skete en fejl!", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    const saveTimes = async () => {
        axios
            .post(
                `https://ckapi.justdiv.studio/settings/update?type=AVAILABLE-TIMES&ip=${await getIp()}`,
                {
                    value: {
                        from: from,
                        to: to,
                    },
                }
            )
            .then((res) => {
                console.log("Times saved!");
                toast("Data er gemt", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            })
            .catch((err) => {
                console.log(err);
                toast("Der skete en fejl!", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    const saveWeekends = async () => {
        await axios
            .post(
                `https://ckapi.justdiv.studio/settings/update?type=WEEKENDS-ENABLED&ip=${await getIp()}`,
                {
                    value: {
                        enabled: weekendsEnabled,
                    },
                }
            )
            .then(() => {
                console.log("Weekends saved!");
                toast("Data er gemt", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            })
            .catch((err) => {
                console.log(err);
                toast("Der skete en fejl!", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    return (
        <>
            <Title className="admin-title" order={1}>
                Indstillinger
            </Title>
            <div
                className="seperator"
                style={{
                    background: "black",
                    height: "1px",
                    width: "100%",
                    marginBottom: 20,
                }}
            />
            <Title order={2} className="mb-4">
                Tilgænglighed
            </Title>
            <div className="flex flex-col md:flex-row gap-20">
                <div className="flex flex-col">
                    <Title order={4}>Dato</Title>
                    <p className="mb-5">
                        Vælg datoer der ikke er tilgænglige for brugere at vælge
                    </p>
                    <Calendar
                        multiple
                        value={dateValues}
                        onChange={setDateValue}
                        className="mx-0 md:mx-auto mb-5"
                    />
                    <Button onClick={() => saveDates()} className="mb-4">
                        Gem
                    </Button>
                </div>
                <div className="flex flex-col">
                    <Title order={4}>Tidspunkt</Title>
                    <p className="mb-2">
                        Vælg de tidspunkter brugeren kan vælge
                    </p>
                    <div className="flex items-center gap-1 mb-2">
                        fra{" "}
                        <Select
                            data={times()}
                            onChange={setFrom}
                            value={from}
                        />{" "}
                        til{" "}
                        <Select data={times()} onChange={setTo} value={to} />
                    </div>
                    <Button onClick={() => saveTimes()} className="mb-10">
                        Gem
                    </Button>
                    <Title order={4}>Weekender</Title>
                    <p className="mb-4">Vælg om du er tilgænglig i weekender</p>
                    <div className="flex mb-4">
                        Weekender:{" "}
                        <Switch
                            className="mx-3 "
                            onChange={(e) =>
                                setWeekendsEnabled(e.target.checked)
                            }
                            checked={weekendsEnabled}
                        />
                        {weekendsEnabled
                            ? "Brugere kan vælge dage i weekenden"
                            : "Brugere kan ikke vælge dage i weekenden"}
                    </div>
                    <Button onClick={() => saveWeekends()}>Gem</Button>
                </div>
            </div>
        </>
    );
};

export default SettingsPanel;
