import React from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Form = () => {
  // make an input ref for each input field
  const nameRef = React.createRef();
  const lastNameRef = React.createRef();
  const emailRef = React.createRef();
  const phoneRef = React.createRef();
  const messageRef = React.createRef();

  // handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    // get values from input fields
    const name = nameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const message = messageRef.current.value;
    // validate input fields
    if (
      name === "" ||
      lastName === "" ||
      email === "" ||
      phone === "" ||
      message === ""
    ) {
      alert("Udfyld alle felter tak");
      // } else if (!validateEmail(email)) {
      //     alert('Indtast venligst en gyldig email');
    } else if (!validatePhone(phone)) {
      alert("Indtast venligst et gyldigt telefonnummer");
    } else {
      // send email
      emailjs
        .send(
          "service_y0u1esl",
          "template_0mhqhq7",
          {
            name: name,
            lastName: lastName,
            email: email,
            phone: phone,
            msg: message,
          },
          "user_X07eRIrDpaIHC6q5zpRvS"
        )
        .then((res) => {
          console.log("success: ", res);
          toast("Din henvendelse er sendt", {
            type: "success",
            position: "bottom-center",
            pauseOnHover: false,
          });
          clearInputs();
        })
        .catch((err) => {
          console.log("error: ", err);
          toast("Der skete en fejl", {
            type: "error",
            position: "bottom-center",
            pauseOnHover: false,
          });
        });
    }
  };
  //phone validation
  const handlePhoneChange = (e) => {
    const phone = e.target.value;
    if (phone.length > 8) {
      alert("Indtast venligst et gyldigt telefonnummer");
    }
  };
  // //email validation
  // const handleEmailChange = (e) => {
  //     const email = e.target.value;
  //     if (!validateEmail(email)) {
  //         alert('Indtast venligst en gyldig email');
  //     }
  // }

  // //validate email input field
  // const validateEmail = (email) => {
  //     const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  //     return re.test(email);
  // }

  //validate phone number input field
  const validatePhone = (phone) => {
    const re = /^\d{8}$/;
    return re.test(phone);
  };

  // clear input fields after submit
  const clearInputs = () => {
    nameRef.current.value = "";
    lastNameRef.current.value = "";
    emailRef.current.value = "";
    phoneRef.current.value = "";
    messageRef.current.value = "";
  };

  return (
    <div className="">
      <form
        action="mailto:info@coachkolding.dk"
        method="post"
        encType="text/plain"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-2 gap-6">
          <div className="relative z-0 w-full mb-3 group">
            <label htmlFor="name">Fornavn</label>
            <input
              type="fanme"
              className="form-control input"
              id="Firstname"
              ref={nameRef}
              required
            />
          </div>
          <div className="relative z-0 w-full mb-3 group">
            <label htmlFor="name">Efternavn</label>
            <input
              type="lname"
              className="form-control input"
              id="Lastname"
              ref={lastNameRef}
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="relative z-0 w-full mb-3 group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              className="form-control input"
              id="email"
              ref={emailRef}
              required
              // onChange={handleEmailChange}
            />
          </div>
          <div className="relative z-0 w-full mb-3 group">
            <label htmlFor="phone">Telefon nummer</label>
            <input
              type="tel"
              className="form-control input"
              id="phone"
              ref={phoneRef}
              onChange={handlePhoneChange}
              required
            />
          </div>
        </div>
        <div className="grid xl:grid-cols-1 ">
          <div className="relative z-0 w-full mb-6 group">
            <label htmlFor="message">Besked</label>
            <textarea
            //   placeholder="Skriv din besked her . . . "
              required
              className="form-control input"
              id="message"
              ref={messageRef}
              rows="3"
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          value="submit"
          className="btn  bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium umami--click--kontakt-mail-sendt"
        >
          Send
        </button>
      </form>
    </div>
  );
};

export default Form;
