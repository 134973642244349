import axios from "axios";

const addLog = async (type, value, ip) => {
    await axios.post("https://ckapi.justdiv.studio/logs", {
        type: type,
        value, value,
        date: new Date(),
        ip: ip
    })
}

export default addLog;