import React from "react";
import data from "../assets/data.json";
import { AnimationOnScroll } from "react-animation-on-scroll";

const navBtn = {
  class:
    "btn bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)] md:hidden mx-auto umami--click--bestil-tid",
  href: "#treatments",
  text: "Bestil tid",
};

const Hero = () => {
  return (
    <main className="relative  overflow-hidden">
      <img
        className="w-36 lg:w-40 grid place-content-center absolute right-0 pt-20 pl-5 pr-8 p-3"
        src={data.footer.npl.logo}
        alt=""
      />

      <div className="opacity-60 h-full w-screen bg-white absolute z-5"></div>
      <img
        className="object-cover w-screen absolute h-full "
        src={data.home.img}
        alt={data.home.alt}
        style={{ zIndex: -1 }}
      />
      <div className=" relative  h-screen flex justify-center ">
        <div className=" z-20 container   my-auto flex-col flex justify-center  sm:py-24 fade-in">
          <div className=" mx-auto  w-11/12 sm:w-4/5 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
            <h1 className="text-3xl mb-2 sm:text-3xl md:text-5xl lg:text-5xl xl:text-6xl text-center text-gray-800 font-black leading-7 md:leading-10">
              {data.home.title}
              <br />
              <span className="text-[color:var(--primary)]">
                {data.home.span}
              </span>
            </h1>
            <p className="lg:mt-5 sm:mt-5 lg:w-10/12 text-gray-500 font-normal text-center text-sm sm:text-lg">
              {data.home.description}
            </p>
          </div>
          <button
            onClick={() => (window.location.href = navBtn.href)}
            className={navBtn.class}
          >
            {navBtn.text}
          </button>
          <div className="mt-80 self-center absolute ">
            <i className="text-center  bx bx-down-arrow-alt text-5xl animate-bounce text-gray-500"></i>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Hero;
