import React, { useState, useEffect } from "react";
import { Title, NativeSelect, Text, Select, Indicator } from "@mantine/core";
import { Calendar } from "@mantine/dates";
import { Clock } from "tabler-icons-react";
import axios from "axios";
import "dayjs/locale/da";
import "../../style/bestilling/date.scss";
import useWindowSize from "../helpers/useWindowSize";
import { toast } from "react-toastify";

let datesTaken = [
    { date: 4, month: 4, year: 2022, timesTaken: [12, 15, 10] },
    { date: 26, month: 5, year: 2022, timesTaken: [12, 15, 10] },
    { date: 26, month: 5, year: 2023, timesTaken: [12, 15, 10] },
    { date: 25, month: 4, year: 2022, timesTaken: [12, 15, 10] },
    { date: 13, month: 4, year: 2022, timesTaken: [12, 10] },
];

const Date = (props) => {
    const [value, setValue] = useState(null);
    const [timeValue, setTimeValue] = useState(null);
    const [availableTimes, setAvailableTimes] = useState([]);
    const [takenTimes, setTakenTimes] = useState({ timesTaken: [] });
    const { width, height } = useWindowSize();
    const [datesUnavailable, setDatesUnavailable] = useState([]);
    const [weekendsEnabled, setWeekendsEnabled] = useState(false);

    useEffect(() => {
        getTimes();
        getWeekendsEnabled();
        if (props.formDate != null) {
            const getTimesNew = async () => {
                const response = await axios.get(
                    `https://ckapi.justdiv.studio/appointments?date=${props.formDate.getDate()}&month=${props.formDate.getMonth()}&year=${props.formDate.getFullYear()}`
                );
                setTakenTimes(response.data);
            };
            getTimesNew();
        }
        if (datesUnavailable.length <= 0) getDatesUnavailable();
    }, [props.formDate]);

    const getTimes = async () => {
        await axios
            .get("https://ckapi.justdiv.studio/settings?type=AVAILABLE-TIMES")
            .then((res) => {
                setAvailableTimes(res.data.setting.value);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getWeekendsEnabled = async () => {
        await axios
            .get("https://ckapi.justdiv.studio/settings?type=WEEKENDS-ENABLED")
            .then((res) => {
                setWeekendsEnabled(res.data.setting.value.enabled);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAvaliableTimes = (date, month, year, lol) => {
        let times = [];
        for (let i = availableTimes.from; i <= availableTimes.to; i++) {
            let disabled = false;
            if (takenTimes.timesTaken.includes(i)) {
                disabled = true;
            }
            times.push({
                value: i,
                label: `${i < 10 ? `0${i}` : i}:00`,
                disabled: disabled,
            });
        }
        return times;
    };

    const getDatesUnavailable = async () => {
        setDatesUnavailable([]);
        axios
            .get("https://ckapi.justdiv.studio/settings?type=TIMES-EXCLUDED")
            .then((res) => {
                const times = res.data.setting.value.times;
                let newTimes = [];
                for (let i = 0; i < times.length; i++) {
                    const date = Number(times[i]);
                    newTimes.push(date);
                }
                const sortedTimes = [...new Set(newTimes)];
                setDatesUnavailable(sortedTimes);
            })
            .catch((err) => {
                console.log(err);
                toast(err, {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    return (
        <section
            className="date section  flex justify-center 
        "
        >
            <div>
                <Title order={3} className="text-center pb-5">
                    Dato
                </Title>
                <div className="dateSelect">
                    <Text className="text-gray-700 pl-5 md:pl-0">
                        Vælg dag på måneden
                    </Text>
                    <Calendar
                        size={width <= 400 ? "sm" : "lg"}
                        value={props.formDate}
                        onChange={props.setFormDate}
                        hideOutsideDates={true}
                        className="calendar"
                        locale="da"
                        allowLevelChange={false}
                        excludeDate={(date) =>
                            (date.getDay() === 0 && !weekendsEnabled) ||
                            (date.getDay() === 6 && !weekendsEnabled) ||
                            date < new window.Date() ||
                            date >
                                new window.Date().setMonth(
                                    new window.Date().getMonth() + 3
                                ) ||
                            datesUnavailable.includes(date.getTime())
                        }
                        // minDate={dayjs(new Date()).startOf('month').add(5, 'days').toDate()}
                        minDate={new window.Date().setMonth(
                            new window.Date().getMonth() - 1
                        )}
                        maxDate={new window.Date().getMonth() + 1}
                    />
                </div>
                <Select
                    label="Hvilken tid vil du have behandlingen?"
                    description="De grå tider, er allerede optaget."
                    placeholder="Vælg en tid"
                    data={
                        props.formDate === null
                            ? []
                            : getAvaliableTimes(
                                  props.formDate.getDate(),
                                  props.formDate.getMonth(),
                                  props.formDate.getFullYear(),
                                  props.formDate
                              )
                    }
                    size="md"
                    className="py-5 mx-auto w-11/12 md:w-full"
                    icon={<Clock size={16} />}
                    onChange={props.setFormTime}
                    error={
                        props.formDate == null ||
                        props.formDate < new window.Date()
                            ? (true,
                              props.formDate <= new window.Date()
                                  ? "Datoen er ikke gyldig"
                                  : "Vælg en dato først")
                            : false
                    }
                    disabled={
                        props.formDate == null ||
                        props.formDate < new window.Date()
                            ? true
                            : false
                    }
                />
            </div>
        </section>
    );
};

export default Date;
