import React from "react";
import data from "../assets/data.json";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import { PopupButton } from "react-calendly";

const Treatments = () => {
  return (
    <section
      id="treatments"
      className="pt-16 pb-10 max-w-screen 
        md:container md:mx-auto "
    >
      <h3
        className="text-center font-bold text-2xl font-serif  
            md:text-4xl lg:py-10 pb-8"
      >
        Behandlinger
      </h3>

      <div
        className=" mx-auto grid gap-y-7 justify-items-center text-center 
            md:grid-cols-2 md:gap-x-32
            lg:grid-cols-2 lg:gap-x-32 
            xl:grid-cols-3 
            "
      >
        {data.treatments.map((item, i) => (
          <AnimationOnScroll key={i} animateIn="animate__fadeIn">
            <div
              className="  w-max rounded-lg hover:shadow-md 
                        md:p-3  container py-4 
                    md:hover:shadow-xl hover:bg-gray-50"
            >
              <img
                className="w-64  lg:w-80 mx-auto"
                src={item.image}
                alt={item.alt}
              />
              <div className="p-2">
                <h3 className="text-gray-900 font-semibold text-2xl  ">
                  {item.name}
                </h3>
                <span className="text-gray-400 text-sm ">{item.subtitle}</span>
              </div>

              <div className={"text-center w-80 mx-auto " + item.textOrder}>
                <p className=" px-1 md:px-1 text-gray-500 text-base pb-3">
                  {item.description}
                </p>
                <div>
                  <div className="mt-3 w-full px-5 flex justify-between">
                    <div className="  ">
                      <p className=" align-middle inline-block text-left text-sm text-gray-400   ">
                        {item.time}
                      </p>
                    </div>

                    {/* <button
                                            className="text-lg hover:shadow bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)]  px-7 py-1 rounded text-white"
                                            href="http://"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Bestil
                                        </button> */}
                    {/* <PopupButton
                                            url={item.link}
                                            className="text-lg hover:shadow bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)]  px-7 py-1 rounded text-white"
                                            rootElement={document.getElementById(
                                                "root"
                                            )}
                                            text="Bestil"
                                        /> */}

                    <div className="">
                      <p className="align-middle inline-block  text-sm text-gray-400   text-right">
                        {item.price}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        ))}
      </div>
    </section>
  );
};

export default Treatments;
