import React from "react";
import data from "../../assets/data.json";

const Aside = () => {
  return (
    <>
      <aside className="h-max absolute mx-auto w-full" style={{ top: -32 }}>
        <div
          className=" bg-[color:var(--form-color)] xl:w-5/6 lg:w-11/12 h-max px-5 pt-10 pb-5 mx-auto rounded-lg drop-shadow-[0_0px_6px_rgba(0,0,0,0.15)] z-20 relative hidden lg:block"
          style={{ zIndex: 20 }}
        >
          {data.about.person.map((i, key) => (
            <div key={key} className="w-full flex">
              <div className="max-h-52  ">
                <img
                  key={key}
                  loading="lazy"
                  className="object-cover h-full  w-full md:scale-2 hidden lg:block
                  "
                  src={i.img}
                  alt={i.alt}
                />
              </div>
              <div className="pl-8">
                <h5 className="text-xl mb-3">{i.title}</h5>
                <ul
                  className="pl-3 pt-1 list-none flex flex-wrap gap-4"
                  style={{ marginLeft: -10 }}
                >
                  {i.list.map((item, i) => (
                    <li
                      className="text-s leading-4 text-[color:var(--primary)] border border-[color:var(--primary)] rounded-full py-1 px-2 hover:bg-[color:var(--primary)] hover:text-white ease-in-out duration-100 cursor-pointer"
                      key={i}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </aside>

      {/* <aside className=" w-screen hidden absolute">
        <div className="z-20 absolute w-full h-full overflow-x-hidden">
          <div className=" w-48 md:w-52 pl-40 float-right h-max ">
            {data.about.person.map((i, key) => (
              <div
                className=" py-8 shadow-lg rounded-l-lg h-3/4 w-80 bg-[color:var(--form-color)]
 transform hover:-translate-x-64 transition-transform duration-500 ease-in-out"
              >
                <img
                  key={key}
                  loading="lazy"
                  className=" m-auto h-44 "
                  src={i.img}
                  alt={i.alt}
                />
                <div className="text-center ">
                  <h5 className=" pb-2 text-xl">{i.title}</h5>
                  <ul>
                    {i.list.map((item, i) => (
                      <li className="m-0 text-sm text-gray-500  " key={i}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </aside> */}
    </>
  );
};

export default Aside;

{
  /* <div className="z-20 absolute w-full h-full overflow-x-hidden">
<div className=" w-48 md:w-52 pl-40 float-right h-max ">
  {data.about.person.map((i, key) => (
    <div
      className=" py-8 shadow-lg rounded-l-lg h-3/4 w-80 bg-[color:var(--form-color)]
 transform hover:-translate-x-64 transition-transform duration-500 ease-in-out"
    >
      <img
        key={key}
        loading="lazy"
        className=" m-auto h-44 "
        src={i.img}
        alt={i.alt}
      />
      <div className="text-center ">
        <h5 className=" pb-2 text-xl">{i.title}</h5>
        <ul>
          {i.list.map((item, i) => (
            <li className=" text-sm text-gray-500  " key={i}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  ))}
</div>
</div> */
}
