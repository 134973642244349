import React, { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import data from "../assets/data.json";

/* navigation  */
const navigation = [
    { name: "Hjem", href: "#", current: true },
    { name: "Omkring", href: "#about", current: false },
    { name: "Behandlinger", href: "#treatments", current: false },
    { name: "Kontakt", href: "#contact", current: false },
];
const navBtn = {
    class: "btn bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)] umami--click--bestil-tid",
    href: "#bestil",
    text: "Bestil tid",
};
export default function Nav() {
    const [activeLink, setActiveLink] = useState("");
    const handleLinkClick = (event) => {
        setActiveLink(event.currentTarget.getAttribute("href"));
    };
    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }
    /*  */

    return (
        <Disclosure as="nav" className="bg-white ">
            {({ open }) => (
                <nav className="transition ease-in-out fixed w-full mx-auto px-2 sm:px-6 lg:px-8  h-max  z-50 backdrop-blur-md bg-white/30 ">
                    <div className="relative  w-full items-center justify-between h-16  inline-block align-middle ">
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start ">
                            {/* logos  */}
                            <div className="flex-shrink-0 flex items-center mr-auto ">
                                <img
                                    className="block p-3 md:hidden h-16 w-auto"
                                    src={data.logo.small}
                                    alt={data.logo.alt}
                                />
                                <img
                                    className="hidden md:block h-16 w-auto md:pb-2"
                                    src={data.logo.big}
                                    alt={data.logo.alt}
                                />
                            </div>
                            {/*  */}

                            {/* navigation */}
                            <div className="hidden sm:block  sm:ml-6 py-3">
                                <div className="  space-x-4 ">
                                    {navigation.map((item, index) => (
                                        <a
                                            key={index}
                                            href={item.href}
                                            onClick={handleLinkClick}
                                            className={classNames(
                                                "px-3 py-2  text-sm font-medium",
                                                activeLink === item.href
                                                    ? "border-b border-lime-700 text-lime-700 "
                                                    : "text-gray-500 hover:text-lime-700"
                                            )}
                                        >
                                            {item.name}
                                        </a>
                                    ))}

                                    <button
                                        className={navBtn.class}
                                        onClick={() => {
                                            window.location.href = navBtn.href;
                                        }}
                                    >
                                        {navBtn.text}
                                    </button>
                                
                                </div>
                            </div>
                            {/*  */}
                        </div>

                        {/* Mobile menu button*/}
                        <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                            <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XIcon
                                        className="block h-6 w-6"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    <MenuIcon
                                        className="block h-6 w-6"
                                        aria-hidden="true"
                                    />
                                )}
                            </Disclosure.Button>
                        </div>
                        {/*  */}
                    </div>

                    {/* Mobile menu */}
                    <Disclosure.Panel className="sm:hidden">
                        <div className=" px-2    ">
                            <ul className="flex flex-col text-center">
                                {/* <button href={navBtn.href} className={"w-20 mx-auto  " + navBtn.class}>
                  {navBtn.text}
                </button> */}
                                {navigation.map((item, index) => (
                                    <a
                                        key={index}
                                        href={item.href}
                                        onClick={handleLinkClick}
                                        className={classNames(
                                            "px-3 py-2  text-sm font-medium",
                                            activeLink === item.href
                                                ? "bg-[color:var(--primary)] w-32 mx-auto text-white rounded"
                                                : "text-gray-400 hover:text-lime-700"
                                        )}
                                    >
                                        {item.name}
                                    </a>
                                ))}
                            </ul>
                        </div>
                    </Disclosure.Panel>
                    {/*  */}
                </nav>
            )}
        </Disclosure>
    );
}
