import React from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

const Bestil = () => {
  return (
    <section
      className="snap-top bg-[color:var(--primary)]   p-2 min-h-max pb-20"
      id="bestil"
    >
      <div className="section-container">
        <AnimationOnScroll animateIn="animate__fadeIn">
          <h3 className="text-center text-white font-bold text-5xl pb-8 pt-20">
            BESTIL TID
          </h3>
          <p className="text-center text-lg font-light text-gray-100">
            Kontakt mig for at bestille tid
          </p>
          <div className="flex h-full  container mx-auto lg:px-5 ">
            {/* <button className="text-lg hover:shadow bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)]  px-7 py-1 rounded text-white">
          Ring
         </button>

         <button className="text-lg hover:shadow bg-[color:var(--primary)] hover:bg-[color:var(--primary-hover)]  px-7 py-1 rounded text-white">
        Mail
         </button> */}
          </div>
        </AnimationOnScroll>
      </div>
    </section>
  );
};
export default Bestil;
