import React from 'react';
import data from "../../assets/data.json";

const ContactInfo = () => {
  return (
    <div>
      <ul className='flex flex-col gap-1 md:gap-5'>
        {data.contact.map((item, i) => (
          <li key={i} className='inline-block align-middle text-sm md:text-base'>
            <i className={"inline-block align-middle pr-3 md:pr-5 text-2xl font-bold " + item.icon}></i><p className='font-semibold inline-block align-middle '>{item.info}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ContactInfo