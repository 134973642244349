import React from "react";
import data from "../../assets/data.json";
import Aside from "./Aside";
import { AnimationOnScroll } from "react-animation-on-scroll";

const About = () => {
  return (
    <section
      id="about"
      className=" snap-start bg-[color:var(--secondary)] pb-10 max-w-screen relative flex justify-center items-center pt-5 md:pt-32 lg:pt-72"
    >
      {/* overflow-x-hidden */}

      <AnimationOnScroll animateIn="animate__fadeIn">
        <Aside />
        <div className="flex justify-center items-center h-full ">
          <div
            className="py-0
                                   my-auto w-full
                                   "
          >
            <div
              className=" text-center px-0
      "
            >
              <span className="text-xl text-slate-600 text-inherit ">
                {data.about.span}
              </span>
              <h3
                className=" font-semibold pb-4
                                            text-3xl
                                            md:text-4xl
                                          lg:text-5xl
                                          "
              >
                {data.about.title}
              </h3>
              <hr
                className=" w-56 mx-auto border border-[color:var(--primary)]
          my-0
          md:my-1
          lg:my-3"
              />
              <p
                className="text-inherit text-slate-500  p-4 
          text-sm w-full mx-auto
        md:text-base md:px-20 md:w-5/6
        lg:text-lg lg:w-2/3 


        "
              >
                {data.about.description}
              </p>
            </div>

            <div
              className="flex pt-5 justify-center 
        "
            >
              <img
                className="w-1/2 h-auto md:w-auto md:h-48 lg:h-48"
                src={data.about.images[0].img}
                alt={data.about.images[0].alt}
              />
              <img
                className="w-1/2 h-auto md:w-auto md:h-48 lg:h-48"
                src={data.about.images[1].img}
                alt={data.about.images[1].alt}
              />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
    </section>
  );
};

export default About;
