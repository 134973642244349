import React, { useState, useEffect, useRef } from "react";
import {
    Title,
    Table,
    ActionIcon,
    Tooltip,
    TextInput,
    PasswordInput,
    Button,
    Modal,
    Autocomplete,
    LoadingOverlay,
} from "@mantine/core";
import { SHA256 } from "crypto-js";
import { Pencil, Trash, X, Plus } from "tabler-icons-react";
import axios from "axios";
import getIp from "../helpers/getIp";

import "../../style/admin/userspanel.scss";
import { toast } from "react-toastify";

const UsersPanel = (props) => {
    const [users, setUsers] = useState([]);
    const [filterUser, setFilterUser] = useState([]);
    const [addUserModal, setAddUserModal] = useState(false);

    const [newName, setNewName] = useState("");
    const [newSurname, setNewSurname] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newPhone, setNewPhone] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const [confirmModal, setConfirmModal] = useState(false);
    const confirmBtn = useRef();
    const [userToDelete, setUserToDelete] = useState("");

    const [updateModal, setUpdateModal] = useState(false);
    const [userToUpdate, setUserToUpdate] = useState("");
    const [updatePassword, setUpdatePassword] = useState("");

    const [loading, setLoading] = useState(false);

    const getUsers = async () => {
        await axios
            .get("https://ckapi.justdiv.studio/login/getAllUsers")
            .then((res) => {
                setUsers(
                    res.data.users.filter((user) => {
                        return !user.admin;
                    })
                );
                setFilterUser(
                    res.data.users.filter((user) => {
                        return !user.admin;
                    })
                );
            });
    };
    // getUsers();

    useEffect(() => {
        if (users.length === 0 || props.searchValue.length === 0) {
            getUsers();
        }

        if (props.searchValue.length > 0) {
            setFilterUser(
                [...users].filter((user) => {
                    return (
                        user.email
                            .toLowerCase()
                            .includes(props.searchValue.toLowerCase()) ||
                        user.name
                            .toLowerCase()
                            .includes(props.searchValue.toLowerCase()) ||
                        user.surname
                            .toLowerCase()
                            .includes(props.searchValue.toLowerCase()) ||
                        user.phone
                            .toLowerCase()
                            .includes(props.searchValue.toLowerCase())
                    );
                })
            );
            return;
        }
    }, [props.searchValue]);

    const deleteUser = async (email) => {
        setLoading(true);
        try {
            await axios.delete(
                `https://ckapi.justdiv.studio/login/delete?email=${email}&ip=${await getIp()}`
            );
            console.log("deleted");
            getUsers();
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const addUser = async (e) => {
        e.preventDefault();
        setLoading(true);

        await axios
            .post(`https://ckapi.justdiv.studio/login?ip=${await getIp()}`, {
                name: newName,
                surname: newSurname,
                email: newEmail,
                phone: newPhone,
                password: SHA256(newPassword).toString(),
            })
            .then((res) => {
                toast("Bruger oprettet", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
                setAddUserModal(false);
                getUsers();
                setLoading(false);
            })
            .catch((err) => {
                toast(
                    "Der skete en fejl (Det kan være at emailen eller telefon nummeret allerede eksisterer)",
                    {
                        type: "error",
                        position: "bottom-center",
                        pauseOnHover: false,
                    }
                );
                console.log(err);
                setLoading(false);
            });
    };

    const updateUser = async () => {
        setLoading(true);
        axios
            .post(
                `https://ckapi.justdiv.studio/login/update?email=${userToUpdate}&ip=${await getIp()}`,
                {
                    password: SHA256(updatePassword).toString(),
                }
            )
            .then((res) => {
                toast("Bruger opdateret", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
                setUpdateModal(false);
                getUsers();
                setLoading(false);
            })
            .catch((e) => {
                toast("Der skete en fejl", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
                console.log(e);
                setLoading(false);
            });
    };

    return (
        <div className="userspanel">
            <Modal
                opened={confirmModal}
                onClose={() => setConfirmModal(false)}
                title="Er du sikker?"
                withCloseButton={!loading}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <p style={{ marginBottom: 10 }}>
                    Er du sikker på at du vil slette brugeren tilhørende
                    <br />
                    {userToDelete}?
                </p>
                <div className="buttons" style={{ display: "flex", gap: 10 }}>
                    <Button onClick={() => setConfirmModal(false)}>
                        Behold
                    </Button>
                    <Button
                        onClick={() => {
                            deleteUser(userToDelete);
                            setConfirmModal(false);
                        }}
                        style={{ background: "red" }}
                        ref={confirmBtn}
                    >
                        Slet
                    </Button>
                </div>
            </Modal>
            <Modal
                opened={updateModal}
                onClose={() => {
                    setUpdateModal(false);
                    setUpdatePassword("");
                }}
                title="Opdater adgangskode"
                withCloseButton={!loading}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <PasswordInput
                    label="Ny kode"
                    placeholder="********"
                    onChange={(e) => setUpdatePassword(e.target.value)}
                />
                <Button onClick={() => updateUser()}>Gem</Button>
            </Modal>
            <Modal
                opened={addUserModal}
                title="Tilføj bruger"
                onClose={() => setAddUserModal(false)}
                withCloseButton={!loading}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <form
                    action="#"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                    }}
                    onSubmit={addUser}
                >
                    <TextInput
                        label="Fornavn"
                        onChange={(e) => setNewName(e.target.value)}
                        placeholder="John"
                        required
                    />
                    <TextInput
                        label="Efternavn"
                        onChange={(e) => setNewSurname(e.target.value)}
                        placeholder="Doe"
                        required
                    />
                    <TextInput
                        label="Email"
                        onChange={(e) => setNewEmail(e.target.value)}
                        placeholder="john@doe.dk"
                        required
                    />
                    <TextInput
                        label="Telefon"
                        onChange={(e) => setNewPhone(e.target.value)}
                        placeholder="12 34 56 78"
                        required
                    />
                    <PasswordInput
                        label="Adgangskode"
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="*********"
                        required
                    />
                    <Button type="submit">Tilføj bruger</Button>
                </form>
            </Modal>
            <Title className="admin-title" order={1}>
                Brugere
            </Title>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                }}
            >
                <TextInput
                    label="Søg efter bruger"
                    placeholder="Søg efter bruger..."
                    description="Du kan søge efter navn, efternavn, email eller telefonnummer"
                    style={{ width: "20rem" }}
                    onChange={(e) => props.setSearchValue(e.target.value)}
                    value={props.searchValue}
                    rightSection={
                        props.searchValue && (
                            <X
                                size={16}
                                style={{ cursor: "pointer" }}
                                color="#545454"
                                onClick={() => props.setSearchValue("")}
                            />
                        )
                    }
                />
                <Button onClick={() => setAddUserModal(true)}>
                    <Plus /> Tilføj bruger
                </Button>
            </div>
            <p>{filterUser.length} brugere</p>
            <div
                className="tableWrapper"
                style={{
                    maxWidth: "100%",
                    padding: 0,
                    overflow: "auto",
                }}
            >
                <Table
                    striped
                    horizontalSpacing={"xl"}
                    style={{ width: "100%", minWidth: "50rem" }}
                >
                    <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Efternavn</th>
                            <th>Email</th>
                            <th>Telefon nummer</th>
                            <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterUser.map((user, index) =>
                            user.admin ? (
                                <></>
                            ) : (
                                <tr key={index}>
                                    <td>{user.name}</td>
                                    <td>{user.surname}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone}</td>
                                    <td className="actions">
                                        <Tooltip withArrow label="Rediger">
                                            <ActionIcon
                                                color="green"
                                                onClick={() => {
                                                    setUpdateModal(true);
                                                    setUserToUpdate(user.email);
                                                }}
                                            >
                                                <Pencil />
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip withArrow label="Slet">
                                            <ActionIcon
                                                style={{ background: "red" }}
                                                variant="filled"
                                                color="red"
                                                onClick={() => {
                                                    setUserToDelete(user.email);
                                                    setConfirmModal(true);
                                                }}
                                            >
                                                <Trash color="white" />
                                            </ActionIcon>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default UsersPanel;
