import { Title, TextInput, Button, Modal } from "@mantine/core";
import { X } from "tabler-icons-react";
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import getIp from "../helpers/getIp";

const Logs = () => {
    const [logs, setLogs] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmValue, setConfirmValue] = useState("");
    const getLogs = async () => {
        axios
            .get("https://ckapi.justdiv.studio/logs")
            .then((res) => {
                setLogs(res.data.allLogs);
            })
            .catch((e) => {
                toast("Der skete en fejl", {
                    type: "success",
                    pauseOnHover: false,
                    position: "bottom-center",
                });
                console.log(e.message);
            });
    };
    function filterByValue(array, value) {
        return array.filter(
            (item) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1
        );
    }
    const clearLogs = async () => {
        axios
            .delete(
                `https://ckapi.justdiv.studio/logs/clear?ip=${await getIp()}`
            )
            .then(() => {
                getLogs();
                toast("Logs cleared successfully", {
                    type: "success",
                    pauseOnHover: false,
                    position: "bottom-center",
                });
            })
            .catch((e) => {
                toast("Der skete en fejl", {
                    type: "error",
                    pauseOnHover: false,
                    position: "bottom-center",
                });
                console.log(e.message);
            });
    };
    const confirm = () => {
        if (!confirmModal) {
            setConfirmModal(true);
        } else {
            if (confirmValue === "CLEAR LOGS") {
                clearLogs();
                setConfirmValue("");
                setConfirmModal(false);
            }
        }
    };

    useEffect(() => {
        if (logs.length == 0) {
            getLogs();
        }
    }, []);
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                gap: 10,
            }}
        >
            <Modal
                title="Er du sikker?"
                opened={confirmModal}
                onClose={() => setConfirmModal(false)}
            >
                <div
                    className=""
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 10,
                    }}
                >
                    <p>
                        Hvis du er helt sikker på at du vil clear logs, <br />{" "}
                        skal du skrive{" "}
                        <span style={{ color: "red" }}>CLEAR LOGS</span> i
                        boksen under
                    </p>
                    <TextInput
                        onChange={(e) => setConfirmValue(e.target.value)}
                    />
                    <Button
                        style={{ background: "red" }}
                        onClick={() => confirm()}
                    >
                        CLEAR
                    </Button>
                </div>
            </Modal>
            <Title order={3}>Logs</Title>
            <div
                className=""
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: 10,
                }}
            >
                <TextInput
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    label="Søg"
                    placeholder="Søg efter log eller type"
                    rightSection={
                        searchValue && (
                            <X
                                size={16}
                                style={{ cursor: "pointer" }}
                                color="#545454"
                                onClick={() => setSearchValue("")}
                            />
                        )
                    }
                />
                <Button style={{ background: "red" }} onClick={() => confirm()}>
                    Clear logs
                </Button>
                <Button onClick={() => getLogs()}>Reload</Button>
            </div>
            <div
                className="logsWrapper"
                style={{
                    width: "100%",
                    height: "100%",
                    background: "none",
                    background: "#ededed",
                    overflowY: "scroll",
                    display: "flex",
                    flexDirection: "column-reverse",
                    justifyContent: "flex-end",
                }}
            >
                {filterByValue(logs, searchValue).map((log, index) => {
                    const date = new window.Date(log.date);
                    return (
                        <p key={index} style={{ fontFamily: "monospace" }}>
                            <span
                                onClick={() =>
                                    window.open(
                                        `https://whatismyipaddress.com/ip/${log.ip}`,
                                        "_blank"
                                    )
                                }
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                {log.ip}
                            </span>{" "}
                            -{" "}
                            {date.getDate() < 10
                                ? `0${date.getDate()}`
                                : date.getDate()}
                            /
                            {date.getMonth() < 10
                                ? `0${date.getMonth()}`
                                : date.getMonth()}
                            /
                            {date.getFullYear() < 10
                                ? `0${date.getFullYear()}`
                                : date.getFullYear()}{" "}
                            {date.getHours() < 10
                                ? `0${date.getHours()}`
                                : date.getHours()}
                            :
                            {date.getMinutes() < 10
                                ? `0${date.getMinutes()}`
                                : date.getMinutes()}
                            :
                            {date.getSeconds() < 10
                                ? `0${date.getSeconds()}`
                                : date.getSeconds()}{" "}
                            --{" "}
                            <span
                                onClick={() => setSearchValue(log.type)}
                                style={{ cursor: "pointer" }}
                            >
                                {log.type}
                            </span>
                            : {log.value}
                        </p>
                    );
                })}
            </div>
        </div>
    );
};

export default Logs;
