import React from "react";
import data from "../../assets/data.json";

const Footer = () => {
  return (
    <footer>
      <div className="md:flex justify-center  px-15  py-4">
        <div
          className=" py-10 w-3/4 mx-auto
                md:w-2/5 md:mb-0 
                lg:px-10 "
        >
          <img className="w-40 lg:w-28 mx-auto lg:my-[-30px]" src={data.footer.npl.logo} alt="NPL" />
          {/* <h4 className="text-sm  text-center">Website creator</h4>
          <a href="/" className="pb-3 flex items-center justify-center">
            <span className="self-center  text-3xl font-bold whitespace-nowrap text-[color:var(--primary)]">
              JBT.
              <span className="font-medium text-2xl tracking-widest">
                graphic
              </span>
            </span>
          </a> */}
          <p className="text-center text-gray-400"></p>
          {/* <div
            className="flex pt-0  mt-1 space-x-6 justify-center 
                    md:pt-4"
          >
            <a
              href="https://instagram.com/jbt.graphic?igshid=YmMyMTA2M2Y="
              className="footer-icon"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="w-8 h-8"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="/"
              className="footer-icon"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="w-8 h-8"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/feed/"
              className="footer-icon"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="w-7 h-7"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.429 6.969H11.143V8.819C11.678 7.755 13.05 6.799 15.111 6.799C19.062 6.799 20 8.917 20 12.803V20H16V13.688C16 11.475 15.465 10.227 14.103 10.227C12.214 10.227 11.429 11.572 11.429 13.687V20H7.429V6.969V6.969ZM0.57 19.83H4.57V6.799H0.57V19.83V19.83ZM5.143 2.55C5.14315 2.88528 5.07666 3.21724 4.94739 3.52659C4.81812 3.83594 4.62865 4.11651 4.39 4.352C3.9064 4.83262 3.25181 5.10165 2.57 5.1C1.88939 5.09954 1.23631 4.8312 0.752 4.353C0.514211 4.11671 0.325386 3.83582 0.196344 3.52643C0.0673015 3.21704 0.000579132 2.88522 0 2.55C0 1.873 0.27 1.225 0.753 0.747C1.23689 0.268158 1.89024 -0.000299211 2.571 2.50265e-07C3.253 2.50265e-07 3.907 0.269 4.39 0.747C4.872 1.225 5.143 1.873 5.143 2.55Z"
                  fill="#6C727F"
                />
              </svg>
            </a>
            <a
              href="/"
              className="footer-icon"
              target="_blank"
              rel="noreferrer"
            >
              <svg
                className="w-8 h-8"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
          </div> */}
        </div>
        <div
          className="  py-5 text-center w-3/4 mx-auto
                md:w-8/12 
                grid lg:grid-cols-2 lg:gap-10 gap-10 grid-cols-1"
        >
          <div>
            <h2 className="mb-6 text-base font-semibold text-gray-900 uppercase ">
              Firma oplysninger
            </h2>
            <ul className="text-gray-500 ">
              <li className="mb-4">
                <p className="hover:[color:var(--primary)]">
                  CVR-nr.: 36 32 44 65
                </p>
              </li>
              <li>
                <p className="hover:[color:var(--primary)]">
                  Betaling: over MobilePay
                </p>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-base font-semibold text-gray-900 uppercase ">
              Andet hjælp
            </h2>
            <ul className="text-gray-500 ">
              <li className="mb-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nlphuset.dk/"
                  className="footer-txt"
                >
                  Nlp Huset
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://nada-danmark.dk/"
                  className="footer-txt"
                >
                  Nada - Danmark
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="sm:flex sm:items-center sm:justify-between bg-white px-3 py-1">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2022 by{" "}
          <a href="/" className="hover:underline">
            JBT.graphic
          </a>
          .
        </span>
        {/*  &amp; */}
      </div>
    </footer>
  );
};

export default Footer;
