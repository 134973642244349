import React, { useState, useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";
import { Title, Button, TextInput, PasswordInput, Table } from "@mantine/core";
import axios from "axios";
import {
    Users,
    CalendarEvent,
    Settings,
    ChartArea,
    Files,
} from "tabler-icons-react";
import { SHA256 } from "crypto-js";
import { ToastContainer } from "react-toastify";
import useUmami from "@parcellab/react-use-umami";
import "../style/admin/admin.main.scss";

import UsersPanel from "../components/admin/userspanel";
import AppointmentsPanel from "../components/admin/appointmentspanel";
import Analytics from "../components/admin/analytics";
import SettingsPanel from "../components/admin/Settings";
import Logs from "../components/admin/logs";

import getIp from "../components/helpers/getIp";
import addLog from "../components/helpers/addLog";

const Login = (props) => {
    const [emailValue, setEmailValue] = useState("");
    const [passwordValue, setPasswordValue] = useState("");
    const [doubleClick, setDoubleClick] = useState(false);
    const loginPage = useRef();
    const clickLogin = async (e) => {
        e.preventDefault();
        const res = await axios
            .get(
                `https://ckapi.justdiv.studio/login?email=${emailValue}&password=${SHA256(
                    passwordValue
                ).toString()}&ip=${await getIp()}`
            )
            .then(async (res) => {
                if (res.data.user.admin) {
                    console.log("You are admin!");
                    props.setAdmin(true);
                    addLog(
                        "ADMIN-LOGIN",
                        `${res.data.user.email} Logged in as admin!`,
                        await getIp()
                    );
                } else {
                    console.log("You are not admin!");
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
    };
    const debugMode = async (e) => {
        if (e.keyCode == 68) {
            if (doubleClick == false) {
                setDoubleClick(true);
            } else {
                props.setAdmin(true);
                addLog(
                    "DEBUG-LOGIN",
                    "Someone logged in using debug",
                    await getIp()
                );
            }
        }

        setTimeout(() => {
            setDoubleClick(false);
        }, 200);
    };
    useEffect(() => {
        loginPage.current.focus();
        return;
    }, []);
    return (
        <div
            className="login"
            onKeyDown={(e) => debugMode(e)}
            tabIndex="0"
            ref={loginPage}
        >
            <div className="loginWrapper">
                <Title order={2}>Administrator</Title>
                <form onSubmit={(e) => clickLogin(e)} className="login-form">
                    <TextInput
                        type="text"
                        label="Brugernavn"
                        placeholder="John Doe"
                        order={1}
                        required
                        onChange={(e) =>
                            setEmailValue(e.target.value.toLowerCase())
                        }
                    />
                    <PasswordInput
                        label="Adgangskode"
                        placeholder="••••••••••••"
                        order={1}
                        required
                        onChange={(e) => setPasswordValue(e.target.value)}
                    />
                    <Button className="adminView-btn" type="submit">
                        Log ind
                    </Button>
                </form>
            </div>
        </div>
    );
};

const Admin = () => {
    const [activePage, setActivePage] = useState("Analyse");
    const [searchValue, setSearchValue] = useState("");
    const [searchFor, setSearchFor] = useState("");

    useEffect(() => {
        setSearchValue("");
    }, [activePage]);

    const links = [
        { icon: <ChartArea />, name: "Analyse" },
        { icon: <Users />, name: "Brugere" },
        { icon: <CalendarEvent />, name: "Bestilte tider" },
        { icon: <Settings />, name: "Indstillinger" },
        { icon: <Files />, name: "Logs" },
    ];

    return (
        <div className="admin">
            <ToastContainer />
            <div className="nav">
                <div className="nav-header">
                    <Title order={2}>Administrator</Title>
                </div>
                <ul className="nav-links">
                    {links.map((link, index) => (
                        <div
                            className={`nav-links-item ${
                                activePage === link.name ? "active" : ""
                            }`}
                            key={index}
                            onClick={() => setActivePage(link.name)}
                        >
                            {link.icon} {link.name}
                        </div>
                    ))}
                </ul>
            </div>
            <div
                className={`content ${
                    activePage === "Analyse" ? "analytics" : ""
                }`}
                style={{
                    overflowY: "auto",
                    // background: "green",
                }}
            >
                {activePage === "Brugere" ? (
                    <UsersPanel
                        searchValue={searchValue}
                        setSearchValue={(value) => setSearchValue(value)}
                        searchFor={searchFor}
                    />
                ) : activePage === "Bestilte tider" ? (
                    <AppointmentsPanel
                        setActivePage={(page) => setActivePage(page)}
                        setSearchValue={(value) => setSearchValue(value)}
                        setSearchFor={(value) => setSearchFor(value)}
                    />
                ) : activePage === "Indstillinger" ? (
                    <SettingsPanel />
                ) : activePage === "Analyse" ? (
                    <Analytics />
                ) : activePage === "Logs" ? (
                    <Logs />
                ) : null}
            </div>
        </div>
    );
};

const AdminView = () => {
    const [admin, setAdmin] = useState(false);
    useEffect(() => {}, []);
    return (
        <div className="adminView">
            {admin ? (
                <>
                    <Admin />
                </>
            ) : (
                <>
                    <Login setAdmin={(bool) => setAdmin(bool)} />
                </>
            )}
        </div>
    );
};

export default AdminView;
