import React, { useState, useEffect } from "react";
import {
    Title,
    Table,
    ActionIcon,
    Tooltip,
    LoadingOverlay,
    Modal,
    Button,
    Select,
    SegmentedControl,
    Textarea,
    TextInput,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import {
    Pencil,
    Trash,
    ArrowLeft,
    Clock,
    Eye,
    Plus,
    X,
} from "tabler-icons-react";
import axios from "axios";
import data from "../../assets/data.json";
import { toast } from "react-toastify";
import getIp from "../helpers/getIp";

import "../../style/admin/userspanel.scss";

const AppointmentsPanel = (props) => {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState({ editing: false });

    const [newOwner, setNewOwner] = useState(null);
    const [newTime, setNewTime] = useState(null);
    const [fullDate, setFullDate] = useState(null);
    const [newFullDate, setNewFullDate] = useState(new Date());

    const [confirmModal, setConfirmModal] = useState(false);
    const [appointmentToDelete, setAppointmentToDelete] = useState({
        date: "",
        month: "",
        year: "",
        owner: "",
        time: "",
    });

    const [addAppointmentModal, setAddAppointmentModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [comment, setComment] = useState("");
    const [selectedTreatment, setSelectedTreatment] = useState(null);

    const [takenTimes, setTakenTimes] = useState({ timesTaken: [] });

    const [searchValue, setSearchValue] = useState("");

    const getAddUsers = async () => {
        let newUsers = [];
        let usersSorted = [];
        await axios
            .get("https://ckapi.justdiv.studio/login/getAllUsers")
            .then((res) => {
                newUsers = res.data.users;
            })
            .catch((err) => {
                console.log(err);
            });

        for (let i = 0; i < newUsers.length; i++) {
            usersSorted.push({
                value: newUsers[i].email,
                label:
                    newUsers[i].name +
                    " " +
                    newUsers[i].surname +
                    " (" +
                    newUsers[i].email +
                    ")",
            });
        }

        let newTreatments = [];
        for (let i = 0; i < data.treatments.length; i++) {
            newTreatments.push({
                value: data.treatments[i].id,
                label: data.treatments[i].name,
            });
        }

        setTreatments(newTreatments);
        setUsers(usersSorted);
    };

    const addAppointment = async (e) => {
        e.preventDefault();
        setLoading(true);
        await axios
            .post(
                `https://ckapi.justdiv.studio/appointments?ip=${await getIp()}`,
                {
                    owner: selectedUser,
                    date: selectedDate.getDate(),
                    month: selectedDate.getMonth(),
                    year: selectedDate.getFullYear(),
                    time: selectedTime,
                    comment: comment === "" ? "Ingen kommentar" : comment,
                    treatment: selectedTreatment,
                }
            )
            .then((res) => {
                console.log(res);
                setAddAppointmentModal(false);
                setSelectedUser(null);
                setSelectedDate(null);
                setSelectedTime(null);
                setSelectedTreatment(null);
                setComment("");
                getUsers();
                toast("Aftale er oprettet", {
                    type: "success",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                toast("Der opstod en fejl", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });
    };

    const getUsers = async (reverse) => {
        await axios
            .get("https://ckapi.justdiv.studio/appointments/getAll")
            .then((res) => {
                let newAppointments = res.data.allAppointments;
                newAppointments.forEach((i) => {
                    i.fullDate = new Date(i.year, i.month, i.date);
                });
                let sortedAppointments = newAppointments.sort((a, b) => {
                    if (reverse) {
                        return b.fullDate - a.fullDate;
                    } else {
                        return a.fullDate - b.fullDate;
                    }
                });
                setAppointments(sortedAppointments);
            });
    };

    useEffect(() => {
        getUsers();
        getAddUsers();
    }, []);

    const deleteAppointment = async (owner, date, month, year, time) => {
        setLoading(true);
        try {
            await axios.delete(
                `https://ckapi.justdiv.studio/appointments/delete?owner=${owner}&date=${date}&month=${month}&year=${year}&time=${time}&ip=${await getIp()}`
            );
            console.log("deleted");
            toast("Aftale slettet", {
                type: "success",
                pauseOnHover: false,
                position: "bottom-center",
            });
            getUsers();
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast("Der skete en fejl", {
                type: "error",
                pauseOnHover: false,
                position: "bottom-center",
            });
            setLoading(false);
        }
    };

    const editAppointment = async (owner, date, month, year, time) => {
        setEditing({
            editing: true,
            owner: owner,
            date: date,
            month: month,
            year: year,
            time: time,
        });
        setNewOwner(owner);
        setNewTime(time);
        const newDate = new Date(year, month, date);
        setNewFullDate(newDate);
        // props.setAppointmentSize("md");
    };

    const getAvaliableTimes = (date, month, year) => {
        let times = [];
        for (let i = 0; i <= 24; i++) {
            let disabled = false;
            if (takenTimes.timesTaken.includes(i) && i != editing.time) {
                times.push({
                    value: i,
                    label: `${i < 10 ? `0${i}` : i}:00`,
                    disabled: true,
                });
            } else if (takenTimes.timesTaken.includes(i) && i == editing.time) {
                times.push({
                    value: i,
                    label: `Valgt nu: ${i < 10 ? `0${i}` : i}:00`,
                    disabled: false,
                });
            } else {
                times.push({
                    value: i,
                    label: `${i < 10 ? `0${i}` : i}:00`,
                    disabled: false,
                });
            }
        }
        return times;
    };

    const saveChanges = async () => {
        const { owner, date, month, year, time } = editing;
        const newDate = newFullDate.getDate();
        const newMonth = newFullDate.getMonth();
        const newYear = newFullDate.getFullYear();
        setLoading(true);
        axios
            .post(
                `https://ckapi.justdiv.studio/appointments/update?qowner=${owner}&qdate=${date}&qmonth=${month}&qyear=${year}&qtime=${time}&ip=${await getIp()}`,
                {
                    owner: newOwner,
                    date: newDate,
                    month: newMonth,
                    year: newYear,
                    time: newTime,
                }
            )
            .then(() => {
                setEditing({ editing: false });
                getUsers();
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setLoading(false);
                toast("Der opstod en fejl", {
                    type: "error",
                    position: "bottom-center",
                    pauseOnHover: false,
                });
            });

        // props.setAppointmentSize("xl");
    };

    const refresh = () => {
        getUsers();
    };

    const goToUser = (owner) => {
        props.setActivePage("Brugere");
        setTimeout(() => props.setSearchValue(owner), 200);
    };

    const setSort = (value) => {
        // setAppointments([]);
        if (value === "newest") {
            getUsers();
        } else {
            getUsers(true);
        }
    };

    function filterByValue(array, value) {
        return array.filter(
            (item) =>
                JSON.stringify(item)
                    .toLowerCase()
                    .indexOf(value.toLowerCase()) !== -1 ||
                // data.treatments[item.treatment].name
                //     .toLowerCase()
                //     .includes(value.toLowerCase()) ||
                `${data.treatments[item.treatment].name} ${
                    data.treatments[item.treatment].subtitle
                }`
                    .toLowerCase()
                    .includes(value.toLowerCase())
        );
    }

    return (
        <div className="appointmentspanel">
            <Modal
                opened={editing.editing}
                onClose={() => setEditing({ editing: false })}
                withCloseButton={!loading}
                title="Rediger aftale"
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <div className="edit">
                    <DatePicker
                        label="Vælg ny dato"
                        placeholder={`Nuværende: ${editing.date} / ${editing.month} / ${editing.year}`}
                        value={newFullDate}
                        onChange={setNewFullDate}
                    />
                    <Select
                        label="Vælg nyt tidspunkt"
                        placeholder="Vælg en tid"
                        data={getAvaliableTimes(
                            newFullDate.getDate(),
                            newFullDate.getMonth(),
                            newFullDate.getFullYear()
                        )}
                        style={{ width: "100%" }}
                        className="timeSelect"
                        icon={<Clock size={16} />}
                        onChange={setNewTime}
                        defaultValue={editing.time}
                    />
                    <Button onClick={() => saveChanges()} className="button">
                        Save
                    </Button>
                </div>
            </Modal>
            <Modal
                opened={confirmModal}
                onClose={() => setConfirmModal(false)}
                title="Er du sikker?"
                withCloseButton={!loading}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <p style={{ marginBottom: 10 }}>
                    Er du sikker på at du vil slette aftalen den
                    <br />
                    {appointmentToDelete.date} / {appointmentToDelete.month + 1}{" "}
                    / {appointmentToDelete.year} kl. {appointmentToDelete.time}
                    :00 fra {appointmentToDelete.owner}?
                </p>
                <div className="buttons" style={{ display: "flex", gap: 10 }}>
                    <Button onClick={() => setConfirmModal(false)}>
                        Behold
                    </Button>
                    <Button
                        onClick={() => {
                            deleteAppointment(
                                appointmentToDelete.owner,
                                appointmentToDelete.date,
                                appointmentToDelete.month,
                                appointmentToDelete.year,
                                appointmentToDelete.time
                            );
                            setConfirmModal(false);
                        }}
                        style={{ background: "red" }}
                    >
                        Slet
                    </Button>
                </div>
            </Modal>
            <Modal
                opened={addAppointmentModal}
                onClose={() => setAddAppointmentModal(false)}
                title="Tilføj aftale"
                withCloseButton={!loading}
            >
                <div className="relative">
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <form
                        onSubmit={addAppointment}
                        action="#"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                        }}
                    >
                        <Select
                            data={users}
                            onChange={setSelectedUser}
                            searchable
                            label="Vælg bruger"
                        />
                        <Select
                            data={treatments}
                            onChange={setSelectedTreatment}
                            searchable
                            label="Vælg behandling"
                        />
                        <DatePicker
                            label="Vælg dato"
                            placeholder="Vælg en dato"
                            onChange={setSelectedDate}
                        />
                        <Select
                            label="Vælg tidspunkt"
                            placeholder="Vælg en tid"
                            data={getAvaliableTimes(
                                newFullDate.getDate(),
                                newFullDate.getMonth(),
                                newFullDate.getFullYear()
                            )}
                            style={{ width: "100%" }}
                            className="timeSelect"
                            icon={<Clock size={16} />}
                            onChange={setSelectedTime}
                        />
                        <Textarea
                            label="Kommentar"
                            placeholder="Skriv en kommentar"
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <Button type="submit">Gem aftale</Button>
                    </form>
                </div>
            </Modal>
            <Title className="admin-title" order={1}>
                Bestilte tider
            </Title>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                }}
            >
                <SegmentedControl
                    data={[
                        { label: "Nyeste først", value: "newest" },
                        { label: "Ældste først", value: "oldest" },
                    ]}
                    onChange={(e) => {
                        setSort(e);
                    }}
                />
                <TextInput
                    onChange={(e) => setSearchValue(e.target.value)}
                    value={searchValue}
                    label="Søg"
                    description="Søg efter bruger, behandling eller kommentar"
                    placeholder="Søg..."
                    rightSection={
                        searchValue && (
                            <X
                                onClick={() => setSearchValue("")}
                                size={15}
                                color="#545454"
                                style={{ cursor: "pointer" }}
                            />
                        )
                    }
                />
                <Button onClick={() => setAddAppointmentModal(true)}>
                    <Plus /> Tilføj aftale
                </Button>
            </div>
            {filterByValue(appointments, searchValue).lenght <= 0
                ? "0"
                : filterByValue(appointments, searchValue).length}{" "}
            aftale
            {filterByValue(appointments, searchValue).length > 1 && "r"}
            <div
                className="tableWrapper"
                style={{
                    maxWidth: "100%",
                    padding: 0,
                    overflow: "auto",
                    height: "max-content",
                    overflowY: "hidden",
                }}
            >
                <Table
                    striped
                    horizontalSpacing={"xl"}
                    style={{ width: "100%", minWidth: "62rem" }}
                >
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Behandling</th>
                            <th>Dato</th>
                            <th>Tid</th>
                            <th>Kommentar</th>
                            <th>Handlinger</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterByValue(appointments, searchValue).map(
                            (appointment, index) => (
                                <tr key={index}>
                                    <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            setSearchValue(appointment.owner)
                                        }
                                    >
                                        {appointment.owner}
                                    </td>
                                    <td
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                            data.treatments[
                                                appointment.treatment
                                            ].subtitle
                                                ? setSearchValue(
                                                      data.treatments[
                                                          appointment.treatment
                                                      ].subtitle
                                                  )
                                                : setSearchValue(
                                                      data.treatments[
                                                          appointment.treatment
                                                      ].name
                                                  )
                                        }
                                    >
                                        {
                                            data.treatments[
                                                appointment.treatment
                                            ].name
                                        }
                                        {data.treatments[appointment.treatment]
                                            .subtitle &&
                                            data.treatments[
                                                appointment.treatment
                                            ].subtitle}
                                    </td>
                                    <td style={{ display: "flex" }}>
                                        {appointment.date} /{" "}
                                        {appointment.month + 1} /{" "}
                                        {appointment.year}
                                    </td>
                                    <td>{appointment.time}:00</td>
                                    <td>{appointment.comment}</td>
                                    <td
                                        className="actions"
                                        style={{
                                            position: "relative",
                                            transform: "translateY(5.2px)",
                                        }}
                                    >
                                        <Tooltip label="Rediger tid">
                                            <ActionIcon
                                                onClick={() =>
                                                    editAppointment(
                                                        appointment.owner,
                                                        appointment.date,
                                                        appointment.month,
                                                        appointment.year,
                                                        appointment.time
                                                    )
                                                }
                                            >
                                                <Pencil />
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip label="Gå til bruger">
                                            <ActionIcon
                                                onClick={() =>
                                                    goToUser(appointment.owner)
                                                }
                                            >
                                                <Eye />
                                            </ActionIcon>
                                        </Tooltip>
                                        <Tooltip label="Slet tid">
                                            <ActionIcon
                                                variant="filled"
                                                style={{
                                                    background: "#FA5252",
                                                }}
                                                onClick={() => {
                                                    setConfirmModal(true);
                                                    setAppointmentToDelete(
                                                        appointment
                                                    );
                                                }}
                                            >
                                                <Trash color="white" />
                                            </ActionIcon>
                                        </Tooltip>
                                    </td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

export default AppointmentsPanel;
